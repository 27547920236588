<template>
  <div>
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowModal"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header>
        <h6>{{ modalTitle }}</h6>
        <CButtonClose @click="modalCallBack(false)" />
      </template>
      <template #body-wrapper>
        <LoadingOverlay v-if="!isFetchingCandidatesDetails" />
        <div v-else-if="profile" class="candidate-details">
          <CRow class="f-12 mx-0">
            <CCol lg="4" class="pb-2 dark-block mt-0">
              <div>
                <CandidateInfo
                  :profile="profile"
                  :resumeDocument="resumeDocument"
                  :agencyCVDocument="agencyCVDocument"
                  :enableAddScratchPad="enableAddScratchPad"
                />
              </div>
              <div>
                <ContactInfo
                  v-if="isShowContact"
                  :profile="profile"
                  :isCandidate="true"
                  :isEditable="isEditable"
                />
              </div>
            </CCol>
            <CCol lg="8">
              <CRow class="mb-2">
                <CCol lg="12">
                  <BasicInfo :profile="profile" :isEditable="isEditable" />
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="12" v-if="isNurse">
                  <ProfessionalInfoNurse
                    :profile="profile"
                    :nurseSpeciality="nurseSpeciality"
                    :specialInterest="specialInterest"
                    :qualifications="qualifications"
                    :isEditable="isEditable"
                    :isModalView="true"
                  />
                </CCol>
                <CCol lg="12" v-else-if="isAHP">
                  <ProfessionalInfoAHP
                    :profile="profile"
                    :nurseSpeciality="nurseSpeciality"
                    :specialInterest="specialInterest"
                    :isEditable="isEditable"
                    :isModalView="true"
                  />
                </CCol>
                <CCol lg="12" v-else>
                  <ProfessionalInfo
                    :profile="profile"
                    :isEditable="isEditable"
                    :isModalView="true"
                  />
                </CCol>
                <CCol lg="12">
                  <AvailabilityInfo
                    :profile="profile"
                    :isEditable="isEditable"
                  />
                </CCol>
                <CCol lg="12">
                  <CandidateLanguageInfo />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </template>
      <template #footer>
        <CButton color="secondary" @click="modalCallBack(false)">Close</CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import isJobSupplier from "@/mixins/isJobSupplier";
import { mapActions, mapGetters } from "vuex";
import CandidateInfo from "@/containers/CandidateDetailsTalentFind/CandidateInfo";
import BasicInfo from "@/containers/CandidateDetailsTalentFind/BasicInfo";
import ContactInfo from "@/containers/CandidateDetailsTalentFind/ContactInfo";
import ProfessionalInfo from "@/containers/CandidateDetailsTalentFind/ProfessionalInfo";
import ProfessionalInfoNurse from "@/containers/CandidateDetailsTalentFind/ProfessionalInfoNurse";
import ProfessionalInfoAHP from "@/containers/CandidateDetailsTalentFind/ProfessionalInfoAHP";
import AvailabilityInfo from "@/containers/CandidateDetailsTalentFind/AvailabilityInfo";
import CandidateLanguageInfo from "@/containers/CandidateDetailsTalentFind/CandidateLanguageInfo";
import moment from "moment";
export default {
  mixins: [isJobSupplier],
  props: {
    candidate_uid: {
      type: Number,
      default: null,
    },
    isShowModal: {
      type: Boolean,
      default: false,
    },
    modalColor: {
      type: String,
      default: "primary",
    },
    modalTitle: {
      type: String,
      default: "Candidate Info",
    },
    isShowContact: {
      type: Boolean,
      default: true,
    },
    enableAddScratchPad: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CandidateInfo,
    BasicInfo,
    ContactInfo,
    ProfessionalInfo,
    ProfessionalInfoNurse,
    ProfessionalInfoAHP,
    AvailabilityInfo,
    CandidateLanguageInfo,
  },
  data() {
    return {
      isEditable: false,
    };
  },
  computed: {
    ...mapGetters([
      "candidateProfile",
      "isFetchingCandidatesDetails",
      "getUploadedDocument",
    ]),
    profile() {
      if (this.candidateProfile) {
        return {
          ...this.candidateProfile,
          candidateTypeName: this.candidateProfile.candidate_type
            ? this.candidateProfile.candidate_type.candidate_type
            : null,
          phoneNumber: this.candidateProfile.phone
            ? this.candidateProfile.phone.replace("^", "-")
            : null,
          alternatePhoneNumber: this.candidateProfile.alternate_phone
            ? this.candidateProfile.alternate_phone.replace("^", "-")
            : null,
          whatsappNumber: this.candidateProfile.whatsapp
            ? this.candidateProfile.whatsapp.replace("^", "-")
            : null,
          genderName: this.candidateProfile.gender
            ? this.candidateProfile.gender.gender
            : null,
          nationalityName: this.candidateProfile.nationality_names
            ? this.candidateProfile.nationality_names.join(", ")
            : null,
          birthPlaceName:
            this.candidateProfile?.birth_place?.country_name || null,
          lastLoginDate: this.candidateProfile.last_date_of_login
            ? moment(this.candidateProfile.last_date_of_login).format(
                "DD-MMM-YYYY"
              )
            : "--",
          createdOnDate: this.candidateProfile.created_on
            ? moment(this.candidateProfile.created_on).format("DD-MMM-YYYY")
            : "--",
          _title: this.candidateProfile?.title?.title || null,
        };
      }
      return null;
    },
    resumeDocument() {
      return (
        (this.profile &&
          this.getUploadedDocument.length &&
          this.getUploadedDocument
            .filter(({ document_type_id }) => document_type_id === 1)
            .map(
              ({
                document_type_id,
                uuid_link_to_doc,
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_id,
                comments,
                version_no,
              }) => {
                return {
                  link: uuid_link_to_doc,
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext?.document_ext,
                  document_id: candidate_document_id,
                  comments: comments,
                  document_type_id: document_type_id,
                  version_no: version_no,
                };
              }
            )) ||
        []
      );
    },
    agencyCVDocument() {
      return (
        (this.profile &&
          this.getUploadedDocument.length &&
          this.getUploadedDocument
            .filter(({ document_type_id }) => document_type_id === 20)
            .map(
              ({
                document_type_id,
                uuid_link_to_doc,
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_id,
                comments,
                version_no,
              }) => {
                return {
                  link: uuid_link_to_doc,
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext?.document_ext,
                  document_id: candidate_document_id,
                  comments: comments,
                  document_type_id: document_type_id,
                  version_no: version_no,
                };
              }
            )) ||
        []
      );
    },
    isNurse() {
      return this.profile?.candidate_type_id === 2 || false;
    },
    isAHP() {
      return this.profile?.candidate_type_id === 3 || false;
    },
    nurseSpeciality() {
      return (
        (this.profile?.candidate_specialities &&
          this.profile?.candidate_specialities.filter(
            ({ speciality_id, special_interest_id, other_special_interest }) =>
              speciality_id &&
              special_interest_id === null &&
              other_special_interest === null
          )) ||
        []
      );
    },
    specialInterest() {
      return (
        (this.profile?.candidate_specialities &&
          this.profile?.candidate_specialities.filter(
            ({ special_interest_id, other_special_interest }) =>
              special_interest_id || other_special_interest
          )) ||
        []
      );
    },
    qualifications() {
      return (
        (this.getCandidateQualification &&
          this.getCandidateQualification.map(
            ({
              candidate_qualification_id,
              qualification_id,
              qualification_name,
              qualification,
              country_id,
              country_name,
              year_awarded,
              month_awarded,
              speciality_name,
              sub_speciality_name,
              issue_authority_id,
              issue_authority_name,
              other_issue_authority,
              course_duration,
              distance_learning,
              description,
              comments,
              by_exam,
              other_qualification_name,
              document_ids,
              documents,
              main,
              primary,
            }) => {
              return {
                candidate_qualification_id: candidate_qualification_id,
                qualification_id: qualification_id,
                qualification_name: qualification_name,
                qualification_with_acronym:
                  qualification?.qualification_with_acronym,
                qualification: qualification,
                country_id: country_id,
                country_name: country_name,
                year_awarded: year_awarded,
                month_awarded: month_awarded,
                speciality_name: speciality_name,
                sub_speciality_name: sub_speciality_name,
                issue_authority_id: issue_authority_id,
                issue_authority_name: issue_authority_name,
                other_issue_authority: other_issue_authority,
                course_duration: course_duration,
                distance_learning: distance_learning,
                description: description,
                comments: comments,
                by_exam: by_exam,
                other_qualification_name: other_qualification_name,
                document_ids: document_ids,
                documents: documents,
                main: main,
                primary: primary,
                isFirst:
                  (main && this.profile?.qualifications.length === 1) ||
                  (!main && this.profile?.qualifications.length > 1),
              };
            }
          )) ||
        []
      );
    },
  },
  methods: {
    ...mapActions([
      "setSelectedcandidateById",
      "fetchQualification",
      "fetchUploadedDocument",
    ]),
    modalCallBack(action) {
      this.$emit("modalCallBack", action);
    },
  },
  mounted() {
    this.$store.commit("SET_CANDIDATE", null);
    if (this.candidate_uid)
      this.setSelectedcandidateById(this.candidate_uid).then((res) => {
        if (this.isNurse) {
          this.fetchQualification();
        }
        this.fetchUploadedDocument();
      });
  },
};
</script>