<template>
  <div class="pl-2">
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowModal"
      :closeOnBackdrop="false"
    >
      <template #header>
        <h6>{{ modalTitle }}</h6>
        <CButtonClose @click="popupModalCallBack(false)" />
      </template>

      <template #footer>
        <CButton form="reasons" color="secondary" @click="popupModalCallBack('Cancel', payload)">Cancel</CButton>
        <CButton form="reasons" :color="modalColor" type="Submit" >Confirm</CButton>
      </template>

      <div>
        <ValidationObserver ref="reasons" v-slot="{ handleSubmit }">
          <form id="reasons" @submit.prevent="handleSubmit(onSubmit)">
            <CRow class="row mb-4 content-center">
              {{ modalContent }}
            </CRow>
            <CRow class="row mb-3" v-if="modalJob">
              <label class="required col-lg-5 ">{{ modalJob }}</label>
              <div class="col-lg-7">
                <ValidationProvider ref="jobId" :rules="{ required: true }" v-slot="{ errors }">
                  <Select
                    name="job_id"
                    :value="candidate.job_id"
                    @input="handleChangeSelect"
                    :options="options && options['jobs'] ? options['jobs'] : [] "
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-3" v-if="modalLabel">
              <label class="required col-lg-5 ">{{ modalLabel }}</label>
              <div class="col-lg-7">
                <ValidationProvider ref="reasonId" :rules="{ required: true }" v-slot="{ errors }">
                  <Select
                    name="reason_id"
                    :value="candidate.reason_id"
                    @input="handleChangeSelect"
                    :options="options && options['reasons'] ? options['reasons'] : [] "
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-3">
              <label class="col-lg-5 ">Comments</label>
              <div class="col-lg-7">
                <ValidationProvider :rules="{ required: false }" v-slot="{ errors }">
                  <TextInput
                    name="comments"
                    :value="candidate.comments"
                    @input="handleInput"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import Modal from "@/components/reusable/Modal";
import TextInput from "@/components/reusable/Fields/TextInput";
import Select from "@/components/reusable/Fields/Select";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });

export default {
  extends: Modal,
  name: "PopupModal",
  props: [
    "popupModalCallBack",
    "modalLabel",
    "candidateItem",
    "modalJob",
    "modalReasonType",
  ],
  components: {
    TextInput,
    Select,
  },
  data() {
    return {
      candidate: {},
      payload: {},
    };
  },
  computed: {
    ...mapGetters(["getCandidateJobs", "reasonsFilterByReasonType"]),
    options() {
      return {
        reasons:
          this.reasonsFilterByReasonType({
            reasonType: this.modalReasonType
          }) || [],
        jobs: this.getCandidateJobs || [],
      };
    },
  },
  created() {
      this.getReasons();
  },
  watch: {
    isShowPopup() {
      if (this.isShowPopup === true) {
        this.candidate = {};
        this.payload = {};
      }
      this.$nextTick(() => {
        this.$refs.reasonId.errors.clear;
        this.$refs.reasonId.reset();
        if(this.modalJob) {
          this.$refs.jobId.errors.clear;
          this.$refs.jobId.reset();
        }
      });
    },
  },
  methods: {
    ...mapActions(["getReasons"]),
    handleInput(name, value) {
      Vue.set(this.candidate, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.candidate, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      if(value === null) {
        this.clearFieldErrors(name);
      }
    },
    clearFieldErrors(name) {
      let hasError = {
        job_id: "job_id",
        reason_id: "reason_id"
      }
      this.$nextTick(() => {
        if (name === hasError.job_id) {
          this.$refs.jobId.errors.clear;
          this.$refs.jobId.reset();
        } else if (name === hasError.reason_id) {
          this.$refs.reasonId.errors.clear;
          this.$refs.reasonId.reset();
        }
      });
    },
    async onSubmit() {
      const isValid = await this.$refs.reasons.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.popupModalCallBack("Confirm", this.payload, this.candidateItem);
    },
  },
};
</script>
