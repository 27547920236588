<template>
  <div>
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowModal"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header>
        <h6>{{ modalTitle }}</h6>
        <CButtonClose @click="modalCallBack(false)" />
      </template>
      <template #footer>
        <CButton color="secondary" @click="modalCallBack(false)">Close</CButton>
      </template>
      <template #body-wrapper>
        <LoadingOverlay v-if="!isFetchingJobDetails" />
        <div v-else class="job-details">
          <CRow class="f-12 mx-0">
            <CCol lg="4" class="pb-2 px-0 dark-block mt-0">
              <div>
                <JobInfo
                  :jobDetail="getJobDetailsById"
                  :isEditable="isEditable"
                />
              </div>
              <div>
                <JobContactInfo
                  :profile="getJobDetailsById"
                  :isCandidate="false"
                  :isEditable="isEditable"
                />
              </div>
            </CCol>
            <CCol lg="8" class="pt-1">
              <CRow
                lg="12"
                class="justify-content-end text-primary mb-2 mr-1"
                v-if="isJobCreatedByParent"
              >
                Created By:&nbsp;<strong>{{ isJobCreatedByParent }}</strong>
              </CRow>
              <CRow class="mb-2">
                <CCol lg="12">
                  <ConsultantDetails
                    :profile="getJobDetailsById"
                    :isEditable="isEditable"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="12">
                  <Preferences
                    :profile="getJobDetailsById"
                    :isEditable="isEditable"
                  />
                </CCol>
                <CCol lg="12">
                  <AdditionalInfo
                    :profile="getJobDetailsById"
                    :isEditable="isEditable"
                  />
                </CCol>
              </CRow>
              <CRow v-if="!isCandidateSupplierFromAccessToken">
                <CCol lg="12">
                  <Details
                    :profile="getJobDetailsById"
                    :isEditable="isEditable"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </template>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import JobInfo from "@/containers/JobsDetailedView/JobInfo";
import JobContactInfo from "@/containers/JobsDetailedView/ContactInformation";
import ConsultantDetails from "@/containers/JobsDetailedView/ConsultantDetails";
import Preferences from "@/containers/JobsDetailedView/Preferences";
import AdditionalInfo from "@/containers/JobsDetailedView/AdditionalInfo";
import Details from "@/containers/JobsDetailedView/Details";
export default {
  props: {
    job_id: {
      type: Number,
      default: null,
    },
    isShowModal: {
      type: Boolean,
      default: false,
    },
    modalColor: {
      type: String,
      default: "primary",
    },
    modalTitle: {
      type: String,
      default: "Job Info",
    },
  },
  components: {
    JobInfo,
    JobContactInfo,
    ConsultantDetails,
    Preferences,
    AdditionalInfo,
    Details,
  },
  data() {
    return {
      isEditable: false,
    };
  },
  computed: {
    ...mapGetters([
      "getJobDetailsById",
      "isFetchingJobDetails",
      "isCandidateSupplierFromAccessToken",
    ]),
    isJobCreatedByParent() {
      return this.getJobDetailsById?.created_by_parent_org?.org_name || "";
    },
  },
  methods: {
    ...mapActions(["setSelectedjobById"]),
    modalCallBack(action) {
      this.$emit("modalCallBack", action);
    },
  },
  mounted() {
    this.$store.commit("SET_JOB_DETAILS_BY_ID", null);
    if (this.job_id) this.setSelectedjobById(this.job_id);
  },
};
</script>